import React from "react";
import "./App.css";
import loginImage from "./assets/img/loginImg.png";
import UWCLOGO from "./assets/img/Uwc_logo2.webp";
import { Toaster } from "sonner";
import { useNavigate } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterRouting from "./components/routing/RegisterRouting";
import TokenLogin from "./components/shared/TokenLogin";

const App: React.FC = () => {
  const handleLoginSuccess = () => {
    console.log("User logged in successfully");
    // Redirect or perform additional actions after login
  };
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");
  const partnerName = searchBarParams.get("partner");
  // console.log("partnerName", partnerName)
  return (
    <main>
      <Router>
        <div className="flex h-screen w-screen overflow-hidden">
          <TokenLogin />
          <div className="hidden xl:flex xl:flex-col justify-between xl:gap-4 flex-1 bg-[#000601] relative max-w-[800px]">
            <img
              src={
                "https://images.unitedwecare.com/odoo/open/logo/logo_white_long.png"
              }
              alt="logo"
              width={200}
              className="mt-10 mb-2 ml-8 brightness-[50] z-10 absolute"
            />
            <img
              src={process.env.REACT_APP_LOGIN_IMAGE_URL}
              alt=""
              className="w-full h-screen object-cover"
            />
            {/* <code className="text-white mb-10  text-xs max-w-xl text-center mx-auto">
              Your privacy and security are our top priority. All interactions
              on this platform are protected with advanced encryption and
              managed with strict confidentiality. We implement robust security
              measures to safeguard your personal information
            </code> */}
          </div>
          <div className="flex-1 flex flex-col items-center justify-center px-6 py-14 overflow-hidden h-screen">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register/*" element={<RegisterRouting />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Toaster richColors expand={true} />
    </main>
  );
};

export default App;
