import React from "react";

const UserNameInput = ({
  firstNameVal,
  lastNameVal,
  handleFormData,
  error,
}: any) => {
  return (
    <>
      <div className="flex items-center gap-4">
        <input
          type="text"
          name="firstName"
          value={firstNameVal}
          onChange={(e) => {
            handleFormData("firstName", e.target.value);
          }}
          placeholder="First Name *"
          className="inputClass"
        />
        <input
          type="text"
          name="lastName"
          value={lastNameVal}
          onChange={(e) => {
            handleFormData("lastName", e.target.value);
          }}
          placeholder="Last Name"
          className="inputClass"
        />
      </div>

      <span className="text-red-600 text-xs font-medium mb-2">{error}</span>
    </>
  );
};

export default UserNameInput;
