import React from "react";
import OTPInput from "react-otp-input";

const OtpVerifierInput = ({
  otp,
  setOtp,
  error,
  setErrors,
  counter,
  id,
}: any) => {
  return (
    <form id={id} className="flex flex-col gap-2 mt-5">
      <OTPInput
        value={otp}
        onChange={(val) => {
          setOtp(val);
          setErrors((prevData: any) => ({ ...prevData, otp: "" }));
        }}
        numInputs={4}
        renderSeparator={<span className="text-slate-500"></span>}
        renderInput={(props) => <input disabled={counter > 0} {...props} />}
        inputType="number"
        shouldAutoFocus={counter === 0 ? true : false}
        containerStyle="justify-center gap-2.5"
        inputStyle="border border-solid border-slate-400/60 aspect-square h-12 rounded-md min-w-12 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
      />
      <span className="text-red-600 text-xs font-medium mb-2 text-center">
        {error}
      </span>
    </form>
  );
};

export default OtpVerifierInput;
