import React from "react";

const CountrySelector = ({ handleFormData, countries }: any) => {
  return (
    <div>
      <select
        id="selectedCountry"
        name="selectedCountry"
        className="block w-full rounded-[6px]  pl-3 pr-8 py-1.5 border border-solid border-slate-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        onChange={(e) => handleFormData("selectedCountry", e?.target?.value)}
      >
        <option value="">Choose a country *</option>
        {countries?.map((item: CountryItem) => {
          return (
            <option key={item?.id} value={item.code}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CountrySelector;
