import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useEffect, useState } from "react";
import { X } from "lucide-react";

const Drawer = ({
  isOpen,
  onClose,
  counter,
  onData,
  children,
}: DrawerProps) => {
  useEffect(() => {
    if (counter === 0) {
      onClose();
    }
  }, [counter]);
  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none "
        onClose={onClose}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30 backdrop-blur-lg">
          <div className="flex h-screen items-center justify-center p-4 ">
            <DialogPanel
              transition
              className="w-full max-w-md rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              {/* <div className="">
                <div className="mt-3 text-center sm:mt-5 flex flex-col">
                  <>{children}</>
                </div>
              </div> */}
              <DialogTitle
                as="h3"
                className="text-3xl font-semibold text-center text-red-500 py-3"
              >
                {counter}
              </DialogTitle>
              <p className="mt-2 text-sm/6 text-foreground">
                Oops! You've reached the maximum number of attempts. Please wait
                for the timer to reset before trying again.
              </p>
              <div className="mt-4 flex">
                <Button
                  className="inline-flex ml-auto items-center gap-2 rounded-md bg-primary-500 py-2 px-4 text-sm font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                  onClick={onClose}
                >
                  Okay
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Drawer;
