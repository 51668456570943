import React from "react";

const TimzoneSelector = ({ handleFormData, timezoneList }: any) => {
  return (
    <div className="mt-2.5">
      <select
        id="selectedTimezone"
        name="selectedTimezone"
        className="block w-full rounded-md  pl-3 pr-8 py-2.5 border border-solid border-slate-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        onChange={(e) => handleFormData("selectedTimezone", e?.target?.value)}
      >
        <option>Choose a Timezone *</option>
        {timezoneList?.map((item: TimezoneItem) => {
          return (
            <option key={item?.id} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default TimzoneSelector;
