import { CircleAlert } from "lucide-react";
import { validateEmail } from "../../lib/utils";

const EmailInput = ({ value, handleFormData, error, setErrors }: any) => {
  const isInvalid = !validateEmail(value) && value.length > 0;

  return (
    <>
      {/* <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Email
      </label> */}
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Email *"
          value={value}
          onChange={(e: any) => {
            handleFormData("email", e.target.value);
            setErrors((prevData: any) => ({
              ...prevData,
              email: "",
            }));
          }}
          className={`lowercase w-full border border-solid border-slate-300 rounded-[6px] px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 placeholder:capitalize ${
            isInvalid
              ? "text-red-900 focus:ring-red-600"
              : "focus:ring-indigo-600 "
          }`}
        />
        {isInvalid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CircleAlert aria-hidden="true" className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>

      <span className="text-red-600 text-xs font-medium mb-2">
        {`${isInvalid ? "The email address seems incorrect!" : ""}`}
        {error}
      </span>
    </>
  );
};

export default EmailInput;
