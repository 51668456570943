// Modal.tsx
import React from "react";

import { X } from "lucide-react";

interface ModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  children: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({
  show,
  setShow,
  title,
  children,
}) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black/60"
      onClick={() => setShow(false)}
    >
      <div
        className="fixed left-1/2 top-1/2 z-50 w-full max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 border bg-white p-6 shadow-lg sm:rounded-lg sm:max-w-[600px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold leading-none tracking-tight">
            {title}
          </h3>
          <button className="text-black" onClick={() => setShow(false)}>
            <X />
          </button>
        </div>
        <div className="py-4">{children}</div>
        {/* <div className="flex justify-end">
          <button
            className="bg-black/80 text-white hover:bg-black h-9 px-4 py-2 rounded-md"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default CustomModal;
