import React from "react";

const TncAgreeSwitch = ({handleFormData, isTermsAccepted, userCountry}:any) => {
  let countryCode;
  if(userCountry == "IN" || userCountry == "US") {
    countryCode = userCountry;
  }else{
    countryCode = "US"; // Default to US for non-IN/US users
  }
  return (
    <div className="flex items-center justify-between gap-2 bg-slate-200/60 rounded-lg py-4 px-3 border border-solid border-slate-200/80">
      <span className="text-sm" id="terms-and-conditions">
        <div className="text-sm text-gray-900">
          I agree with{" "}
          <a
            className="text-blue-600 no-underline hover:underline underline-offset-2 cursor-pointer"
            href={`https://legal.unitedwecare.com/${countryCode.toLocaleLowerCase()}/terms-and-conditions/`}
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            className="text-blue-600 no-underline hover:underline underline-offset-2 cursor-pointer"
            href={`https://legal.unitedwecare.com/${countryCode.toLocaleLowerCase()}/privacy-policy/`}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </span>

      {/* <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" --> */}
      <button
        type="button"
        name="isTermsAccepted"
        className={`${
          isTermsAccepted ? "bg-primary-600" : "bg-primary-300"
        }  relative inline-flex h-[20px] w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none `}
        role="switch"
        aria-checked="false"
        aria-labelledby="terms-and-conditions"
        onClick={() =>
          handleFormData("isTermsAccepted", !isTermsAccepted)
        }
      >
        {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
        <span
          aria-hidden="true"
          className={`${
            isTermsAccepted ? "translate-x-5" : "translate-x-0"
          }  pointer-events-none inline-block h-[16px] w-[16px] aspect-square transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
        ></span>
      </button>
    </div>
  );
};

export default TncAgreeSwitch;
