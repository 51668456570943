import React from "react";

const SpecializationSelector = ({ handleFormData, specializations }: any) => {
  return (
    <div>
      <select
        id="location"
        name="location"
        className="block w-full rounded-[6px]  pl-3 pr-8 py-1.5 border border-solid border-slate-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        onChange={(e) => handleFormData("specialization", e?.target?.value)}
      >
        <option value="" className="font-semibold">
          Select specialization *
        </option>

        {specializations?.map((specialization: any, i: number) => (
          <option key={i} value={specialization.name}>
            {specialization.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SpecializationSelector;
