import React, { useState } from "react";
import { CheckReferralCode } from "../../api/_request";
import { toast } from "sonner";

const ReferralInput = ({
  referralCode,
  error,
  setErrors,
  handleFormData,
}: any) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  const checkRefferal = (e: any) => {
    e.preventDefault();
    checkReferrealCodeResponse();
  };

  const checkReferrealCodeResponse = async () => {
    try {
      const res = await CheckReferralCode(referralCode);
      if (res?.ResponseCode === 200) {
        setIsValid(true);
      } else {
        setIsValid(false);

        setErrors((prevData: any) => ({
          ...prevData,
          referralCode: referralCode + " is not valid!",
        }));
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  return (
    <>
      <div className="relative">
        <input
          type="text"
          name="referralCode"
          value={referralCode}
          onChange={(e) => {
            setErrors((prevData: any) => ({
              ...prevData,
              referralCode: "",
            }));

            setIsValid(false);
            handleFormData("referralCode", e.target.value);
          }}
          placeholder="Referral Code (optional)"
          className="inputClass uppercase placeholder:capitalize"
        />
        {referralCode?.length > 0 && (
          <button
            className="px-3 h-[38px] absolute top-0 right-0 uppercase tracking-wide font-medium text-xs"
            onClick={(e) => checkRefferal(e)}
          >
            check
          </button>
        )}
      </div>

      {isValid ? (
        <span className="text-green-600 text-xs font-medium mb-2">
          The <span className="uppercase">{referralCode}</span> referral code
          has been successfully applied.
        </span>
      ) : null}

      <span className="text-red-600 text-xs font-medium mb-2">{error}</span>
    </>
  );
};

export default ReferralInput;
