import { CircleAlert } from "lucide-react";
import { validatePhoneNumber } from "../../lib/utils";
import {
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

const PhoneNumberInput = ({
  value,
  phoneCode,
  countryCode,
  handleFormData,
  isValid,
}: any) => {
  const isInvalid = !isValid && value.length > 0;
  const isValidLength = validatePhoneNumberLength(value, countryCode);
  const isPossible = isPossiblePhoneNumber(value, countryCode);

  //console.log("isPossible", isPossible)

  return (
    <div>
      <div
        className={`flex items-center rounded-[6px] gap-1 relative`}
      >
        <div className="w-full max-w-[50px] absolute left-[1px] top-[1px] rounded-l-[6px] border-0 bg-gray-100 py-1.5 pl-3 pr-10 text-gray-500 shadow-sm sm:text-sm sm:leading-6 font-medium h-[36px]">
          {phoneCode}
        </div>

        <input
          type="number"
          value={value}
          onChange={(e) => handleFormData("mobileNo", e.target.value)}
          placeholder="Mobile Number *"
          className={`w-full border border-slate-300 rounded-[6px] pr-3 pl-[60px] py-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            isInvalid
              ? "text-red-900 focus:ring-red-500"
              : "focus:ring-indigo-600 "
          }`}
          required
        />

        {isInvalid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CircleAlert aria-hidden="true" className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>

      {/* <span className="text-red-600 text-xs font-medium mb-2">{`${
        isValidLength === "TOO_SHORT"
          ? "Hmm, not a phone number yet..."
          : isInvalid
          ? "Double-check that number, please!"
          : ""
      }`}</span> */}

      <span className="text-red-600 text-xs font-medium mb-2">{`${
        isInvalid ? "Invalid Mobile Number!" : ""
      }`}</span>
    </div>
  );
};

export default PhoneNumberInput;
