import React, { useEffect } from "react";
import { GetUserRole, validateToken } from "../../api/_request";
import { setCookieOnClientSide } from "../../lib/utils";
import { Loader2 } from "lucide-react";

const TokenLogin = () => {
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");
  const urlToken = searchBarParams.get("token");
  const utmSource = searchBarParams.get("utm_source");
  const utmMedium = searchBarParams.get("utm_medium");
  const utmCampaign = searchBarParams.get("utm_campaign");
  const utmID = searchBarParams.get("utm_id");

  useEffect(() => {
    if (urlToken) {
      handleLoginViaToken();
    }
  }, []);

  const handleLoginViaToken = async () => {
    if (urlToken) {
      try {
        const TOKENVALIDATIONDATA = await validateToken(urlToken);

        // console.log("TOKENVALIDATIONDATA", TOKENVALIDATIONDATA);

        if (+TOKENVALIDATIONDATA?.ResponseCode === 200) {
          const USERID = TOKENVALIDATIONDATA?.data?.userdetails?.UserId;
          const USERROLE = await GetUserRole(USERID);
          const USERNAME = TOKENVALIDATIONDATA?.data?.userdetails?.Name;
          //console.log("userRole", USERROLE);
          const setCookieBody: SetCookieInterface = {
            token: urlToken,
            userID: USERID,
            utmCampaign,
            utmMedium,
            redirectURL,
            type: "TOKENLOGIN",
            regRef: "uwc_token_login",
            method: "token",
            event: "uwc_token_login",
            authUserRole: USERROLE?.role.toString(),
            provider: "token",
            displayName: USERNAME?.split(" ")[0],
          };
          //console.log("setCookieBody", setCookieBody);
          setCookieOnClientSide(setCookieBody);
        }
      } catch (error) {}
    }
  };
  return (
    <div>
      {urlToken && (
        <div className="fixed w-full h-full top-0 left-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
          <div className="flex flex-col items-center justify-center gap-2">
            <Loader2 className="animate-spin h-10 w-10 text-white" />
            <p className="font-medium text-white text-center">
              Please wait! We are logging you in...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokenLogin;
